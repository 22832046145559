import { graphql, StaticQuery } from "gatsby";
import React from "react";
import "./composablenow.scss";
import "../../templates/resources/resources.scss";
import {
  getPage,
  TypeComposablenowEventPage,
  TypeComposablenowEventQueryResult,
} from "../../utils/queries/composablenow_event";
import {
  PageHeaderCustom,
  PageHeaderStandard,
} from "../../components/page-header/page-header";
import Helmet from "react-helmet";
import { PageTitle } from "../../components/title/title";
import AmazeeIoMetadata from "../../components/metadata";
import RichTextRender from "../../utils/rich-text";
import AmazeePage from "../../components/layout";
import linkResolver from "../../utils/linkResolver";
import usePreviewData from "../../utils/usePreviewData";
import { ResourceInteruptionWide } from "../../templates/resources/customers";
import { TypeHomepageV2Quote } from "../../utils/queries/homepagev2";
import { Resource } from "../../utils/queries/resources";
import { StandardCard } from "../../templates/resources/cards";

const query = graphql`
  {
    allPrismicComposablenowEvent {
      edges {
        node {
          data {
            cta_button_text
            cta_text
            cta_title
            page_meta_description
            title
            meta_title
            hero_image {
              alt
              url
            }
            schedule_image {
              alt
              url
            }
            venue_image {
              alt
              url
            }
            sponsors {
              sponsor_logo {
                url
              }
            }
            speaker_lineup {
              session_description {
                html
                raw
                text
              }
              session_pre_title {
                html
                raw
                text
              }
              session_title {
                html
                raw
                text
              }
              session_teaser_image {
                url
                alt
              }
            }
            session_breaks {
              after_session_number
              break_name {
                html
                raw
                text
              }
            }
            subtitle {
              html
              raw
              text
            }
            about_text {
              html
              raw
              text
            }
            schedule_text {
              html
              raw
              text
            }
            venue_text {
              html
              raw
              text
            }
            faq_text {
              html
              raw
              text
            }
            cta_link {
              document {
                ... on PrismicPageDefinitions {
                  id
                  data {
                    url
                    friendly_name
                  }
                }
              }
            }
            page_meta_thumbnail {
              url
              alt
            }
          }
        }
      }
    }
  }
`;

const ComposablenowEventQueryPage = ({
  data,
  location,
}: {
  location: Location;
  data: TypeComposablenowEventQueryResult;
}) => {
  const pageData = getPage(data);
  if (!pageData) return null;
  const result = usePreviewData(
    pageData,
    "PrismicComposablenowEvent",
    true
  ) as TypeComposablenowEventPage;
  const title = result.meta_title ? result.meta_title : result.title.text;

  return (
    <AmazeePage location={location}>
      <div id="composable-event">
        <Helmet>
          <link rel="stylesheet" href="/css/carousel-single.min.css" />
          <link rel="stylesheet" href="/css/carousel-multi.min.css" />
        </Helmet>
        <PageTitle title={title} amazeeOnly={true} />
        <AmazeeIoMetadata
          title={result.title}
          page_meta_description={result.page_meta_description}
          page_meta_thumbnail={result.page_meta_thumbnail}
          location={location}
        />

        <PageHeaderStandard
          component={PageHeaderCustom}
          header={result.title}
          image={result.hero_image}
          subheader={result.subtitle}
          withSiteName={false}
          hasSectionDivider={false}
        />

        <div id="anchor-nav">
          <div className="container ">
            <div className="flex">
              <div className="col-12 col-md-10">
                <div className="row">
                  <ul>
                    <li>
                      <a href="#about-section">About</a>
                    </li>
                    <li>
                      <a href="#schedule-section">Schedule Overview</a>
                    </li>
                    <li>
                      <a href="#speaker-lineup-section">Session Schedule</a>
                    </li>
                    <li>
                      <a href="#venue-section">The Venue</a>
                    </li>
                    <li>
                      <a href="#faq-section">FAQ</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section id="about">
          <div className="anchor-point" id="about-section"></div>
          <div className="container ">
            <div className="row flex">
              <div className="col-12 col-lg-6">
                {RichTextRender(result.about_text)}
              </div>
              <div className="col-12 col-lg-6 text-center">
                <span>- Organising Sponsors -</span>
                {result.sponsors.map((sponsor, index) => (
                  <div className="sponsor block col-8 col-md-4 mx-auto mb-[4.375rem]">
                    <img
                      src={sponsor.sponsor_logo.url}
                      alt={sponsor.sponsor_logo.alt}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section id="schedule">
          <div className="anchor-point" id="schedule-section"></div>
          <div className="container ">
            <div className="row flex">
              <div className="col-12 col-lg-6">
                {RichTextRender(result.schedule_text)}
              </div>
              <div className="col-12 col-lg-6">
                <img
                  src={result.schedule_image.url}
                  alt={result.schedule_image.alt}
                />
              </div>
            </div>
          </div>
        </section>

        <section id="speaker-linup">
          <div className="anchor-point" id="speaker-lineup-section"></div>
          <div className="resource-cta-wrapper no-mb">
            <div className="resource-cta">
              <div className="row flex justify-center">
                <div className="col-12 col-md-12 flex justify-center flex-col items-center">
                  <div className="text-white text-center">Session Schedule</div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row justify-center">
              {result.speaker_lineup.map((lineupItem, index) => (
                <>
                  {result.session_breaks.map((breakItem, breakIndex) =>
                    breakItem.after_session_number === index ? (
                      <div className="flex breakItem" key={breakIndex}>
                        {RichTextRender(breakItem.break_name.text)}
                      </div>
                    ) : (
                      <div
                        key={index}
                        className="col-12 col-md-6 col-lg-4 resource-card-link"
                      >
                        <div className="resource-card cursor-none hover:none relative h-full">
                          <div
                            className="flex block-wrap flex-col h-full"
                            // style={{ height: 595 }}
                          >
                            <div
                              className="w-full img-wrap flex-shrink-0"
                              style={{ height: 258 }}
                            >
                              <img
                                src={lineupItem.session_teaser_image.url}
                                alt={lineupItem.session_teaser_image.alt}
                                className="resource-image w-full h-full object-cover object-center"
                                loading="lazy"
                              />
                            </div>
                            <div className="w-full content h-full">
                              <div className="content-type">
                                {RichTextRender(
                                  lineupItem.session_pre_title.text
                                )}
                              </div>
                              <div className="flex flex-col h-full">
                                <div className="content-title">
                                  {RichTextRender(
                                    lineupItem.session_title.text
                                  )}
                                </div>
                                <div className="content-description line-clamp">
                                  {RichTextRender(
                                    lineupItem.session_description
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </>
              ))}
            </div>
          </div>
        </section>

        <ResourceInteruptionWide
          cta_text={result.cta_text}
          cta_button_text={result.cta_button_text}
          cta_link={result.cta_link}
          topMargin={false}
        />

        <section id="venue">
          <div className="anchor-point" id="venue-section"></div>
          <div className="container col-lg-6 lg:text-center">
            <div className="mx-auto mb-8">
              {RichTextRender(result.venue_text)}
            </div>
            <img
              className="mx-auto venue-img"
              src={result.venue_image.url}
              alt={result.venue_image.alt}
            />
          </div>
        </section>

        {result.faq_text.text ? 
        <section id="faq">
          <div className="anchor-point" id="faq-section"></div>
          <div className="container ">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-6">
                {RichTextRender(result.faq_text)}
              </div>
            </div>
          </div>
        </section>
        : null}
      </div>
    </AmazeePage>
  );
};

const StaticComposablenowEventQueryPage: React.FC<{ location: Location }> = ({
  location,
}) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => (
        <ComposablenowEventQueryPage location={location} data={data} />
      )}
    />
  );
};
export default StaticComposablenowEventQueryPage;
